import React, { useState } from "react"
import { Box, Container, Heading, Text, Flex } from "@theme-ui/components"
import SwiperCore, { Navigation, Mousewheel, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import ArrowSliderLeft from "../../assets/images/arrow-left.svg"
import ArrowSliderRight from "../../assets/images/arrow-right.svg"
import ProductThumb from "../productThumb"
SwiperCore.use([Navigation, Mousewheel, A11y])

const FeaturedProductsCollection = ({
  kicker,
  title,
  products,
  familyCategories,
  productCategories,
}) => {
  const [prevEl, setPrevEl] = useState(null)
  const [nextEl, setNextEl] = useState(null)

  return (
    <Box>
      <Container sx={{ my: [4] }}>
        <Box>
          <Text as="div" variant="sectionTitle">
            <Flex as="div">
              <span>{kicker}</span>
            </Flex>
          </Text>
        </Box>
        <Box sx={{ pt: 4 }}>
          <Heading variant="h2">{title}</Heading>
        </Box>
        <Box>
          <Swiper
            spaceBetween={24}
            autoHeight={false}
            breakpoints={{
              320: {
                slidesPerView: 1,
              },
              640: {
                slidesPerView: 2,
              },
              896: {
                slidesPerView: 3,
              },
            }}
            navigation={{ prevEl, nextEl }}
          >
            {products.map(product => (
              <SwiperSlide key={products.id}>
                <Box>
                  <ProductThumb
                    product={product}
                    parentFamily={familyCategories}
                    parentCategory={productCategories}
                  />
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>

          <Flex
            sx={{
              mt: [5],
              svg: {
                cursor: "pointer",
                alignItems: "center",
                alignContent: "center",
                width: "50px",
                height: "50px",
                circle: {
                  stroke: "primary",
                  strokeWidth: "2px",
                },
                use: {
                  fill: "primary",
                },
                "g#Group": {
                  fill: "primary",
                },
              },
              ".swiper-button-disabled": {
                svg: {
                  cursor: "pointer",
                  alignItems: "center",
                  alignContent: "center",
                  width: "50px",
                  height: "50px",
                  circle: {
                    stroke: "Gray",
                    strokeWidth: "2px",
                  },
                  use: {
                    fill: "Gray",
                  },
                  "g#Group": {
                    fill: "Gray",
                  },
                },
              },
            }}
          >
            <Box as="div" ref={node => setPrevEl(node)} sx={{ mr: [2] }}>
              <Box as="div" sx={{}}>
                <ArrowSliderLeft />
              </Box>
            </Box>
            <Box as="div" ref={node => setNextEl(node)}>
              <Flex>
                <ArrowSliderRight />
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Container>
    </Box>
  )
}

export default FeaturedProductsCollection
