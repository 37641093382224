import React, { useState } from "react"
import { Box, Container, Grid, Heading, Text, Flex } from "@theme-ui/components"
import { GatsbyImage } from "gatsby-plugin-image"
import { MagicLink } from "../utils/magicLink"
import { getArticlePath, getProductPath } from "../utils/path"
import { InboundLink } from "./link"

const NewsSectionArticleCard = ({ article }) => {
  const [isShown, setIsShown] = useState(false)

  return (
    <Box sx={{ backgroundColor: "lightGrey" }}>
      <InboundLink
        to={getArticlePath(article, article.locale)}
        className="product-thumb-link"
        sx={{ color: "dark" }}
        state={{ article }}
      >
        <Box
          sx={{ minHeight: "480px", height: "480px" }}
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
        >
          <Box
            sx={{
              position: "relative",
              top: 0,
              left: 0,
              height: ["100%"],
              width: "100%",
              "&:hover": {
                ".gatsby-image-wrapper": {
                  opacity: ["1", "1"],
                },
              },
              ".gatsby-image-wrapper": {
                opacity: ["1", "0"],
                height: ["100%"],
                width: "100%",
                zIndex: 1,
                transition: "opacity 0.3s",
              },
              overflow: "hidden",
            }}
          >
            {article.heroImage.blend && (
              <GatsbyImage image={article.heroImage.blend} />
            )}

            <Flex
              sx={{
                p: [4],
                zIndex: 2,
                top: 0,
                left: 0,
                position: "absolute",
                display: "block",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <Box>
                <Heading
                  as="h2"
                  variant="h4"
                  sx={{
                    color: ["light", isShown ? "light" : "dark"],
                  }}
                >
                  {article.title}
                </Heading>
              </Box>
              <Flex
                sx={{
                  justifyContent: "space-between",
                  borderTop: "1px solid",
                  borderColor: ["light", isShown ? "light" : "dark"],
                  alignItems: "center",
                  pt: [4],
                }}
              >
                <Box>
                  <Heading as="p" sx={{ fontSize: [9] }}>
                    {article.meta.firstPublishedAt.split(/(\s+)/)[0]}
                  </Heading>
                </Box>
                <Box
                  sx={{
                    textTransform: "capitalize",
                    textAlign: "right",
                    color: ["light", isShown ? "light" : "dark"],
                  }}
                >
                  <Box>
                    {
                      article.meta.firstPublishedAt
                        .split(/(\s+)/)
                        .filter(e => e.trim().length > 0)[1]
                    }
                  </Box>
                  <Box>
                    {
                      article.meta.firstPublishedAt
                        .split(/(\s+)/)
                        .filter(e => e.trim().length > 0)[2]
                    }
                  </Box>
                </Box>
              </Flex>
            </Flex>
          </Box>
        </Box>
      </InboundLink>
    </Box>
  )
}

export default NewsSectionArticleCard
