import React from "react"
import { Box, Container, Grid, Heading, Text } from "@theme-ui/components"
import { GatsbyImage } from "gatsby-plugin-image"
import FamilyThumb from "../familyThumb"

const FamilyGrid = ({ title, subtitle, items }) => {
  return (
    <Box>
      <Container>
        <Heading as="h1" variant="h2">
          {title}
        </Heading>
        <Box sx={{ pt: [2], pb: [3,5] }}>
          <Text dangerouslySetInnerHTML={{ __html: subtitle }} />
        </Box>
        <Box>
          <Grid columns={[1,2,2, "repeat(12,1fr)"]}>
            {items.slice(0, 5).map((item, index) => (
              <Box
                sx={{
                  maxHeight: index < 2 ? "415px" : "300px",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  gridColumn: [
                    "unset",
                    "unset",
                    "unset",
                    index === 0
                      ? "1/7"
                      : index === 1
                      ? "7/13"
                      : index === 2
                      ? "1/5"
                      : index === 3
                      ? "5/9"
                      : index === 4 && "9/13",
                  ],

                  "> a": {
                    display: "block",
                    position: "relative",
                    height: "100%",
                    width: "100%",
                  },
                }}
              >
                <FamilyThumb family={item} index={index} />
              </Box>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}

export default FamilyGrid
