import React from "react"
import { Box, Container, Grid, Heading, Text, Flex } from "@theme-ui/components"
import { GatsbyImage } from "gatsby-plugin-image"
import ArrowRight from "../../assets/images/arrow-right.svg"
import { InboundLink } from "../link"
import { getProductPath } from "../../utils/path"

const FeaturedProduct = ({
  kicker,
  title,
  subtitle,
  product,
  familyCategories,
  productCategories,
  fullWidth,
}) => {
  console.log(product)
  const Wrapper = fullWidth ? Box : Container
  return (
    <Wrapper
      sx={{
        backgroundColor: "primary",
        py: fullWidth ? [5, 6] : [0, 0],
        px: [0, 0],
      }}
    >
      <Box>
        <Container>
          <Box>
            <Box
              as="div"
              variant="sectionTitle"
              sx={{
                color: "light",
                pb: [3],
                mb: [6],
                borderBottom: "1px solid",
                borderColor: "light",
              }}
            >
              {kicker}
            </Box>
          </Box>
          <Grid columns={[1, 1, "4fr 5fr"]} gap={[16, 32, 64]}>
            <Flex sx={{ flexDirection: "column" }}>
              <Heading variant="h2" sx={{ color: "light", mt: [2, 3, 4] }}>
                {title}
              </Heading>
              <Text
                as="p"
                variant="h5"
                sx={{
                  color: "light",
                  lineHeight: 1.3,
                  mb: [3, 3, 5],
                  mt: [2, 3, 4],
                }}
              >
                {subtitle}
              </Text>
              <InboundLink
                to={getProductPath(
                  product,
                  product.locale,
                  familyCategories,
                  productCategories
                )}
              >
                <Box
                  sx={{
                    border: "1px solid",
                    borderColor: "light",
                    color: "light",
                    py: [3],
                    px: [4],
                    mb: [3, 4],
                    width: "auto",
                    display: "inline-flex",
                    transition: "all .3s ease-out",
                    "&:hover": {
                      color: "dark",
                      backgroundColor: "light",
                      svg: {
                        circle: {
                          stroke: "primary",
                          strokeWidth: "2px",
                        },
                        use: {
                          fill: "primary",
                        },
                        "g#Group": {
                          fill: "primary",
                        },
                      },
                    },
                  }}
                >
                  Scopri di più
                  <Flex
                    variant="svg.arrow.light"
                    sx={{
                      alignItems: "center",
                      alignContent: "center",
                      width: "24px",
                      height: "24px",
                      ml: [3],
                    }}
                  >
                    <ArrowRight />
                  </Flex>
                </Box>
              </InboundLink>
            </Flex>
            <Box>
              <GatsbyImage image={product.images[0].gatsbyImageData} />
            </Box>
          </Grid>
        </Container>
      </Box>
    </Wrapper>
  )
}

export default FeaturedProduct
