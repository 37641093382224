import React from "react"
import {
  Box,
  Container,
  Grid,
  Heading,
  Text,
  Flex,
  Image,
} from "@theme-ui/components"
import { GatsbyImage } from "gatsby-plugin-image"
import FamilyThumb from "../familyThumb"
import RichContentStructuredText from "../richContentStructuredText"
import { MagicLink } from "../../utils/magicLink"
import { ArrowRightCircle } from "react-feather"
import ArrowRight from "../../assets/images/arrow-right.svg"

const DoubleExternalProduct = ({ items }) => {
  return (
    <Box
      sx={{
        background: [
          "unset",
          "unset",
          "linear-gradient(90deg, " +
            items[0].backgroundColor.hex +
            " 45%, " +
            items[1].backgroundColor.hex +
            " 40%)",
        ],
      }}
    >
      <Container sx={{ py: [0, 0, 0, 0], px: [0, 0, 3, 4] }}>
        <Box>
          <Grid columns={[1, 1, 2, 2]} gap={[0]}>
            {items.map((item, index) => (
              <Flex
                sx={{
                  px: [3, 4, 4, 4],
                  pr: [3, 4, index === 0 && 9],
                  pl: [3, 4, index === 1 && 9],
                  py: [6, 8],
                  backgroundColor: item.backgroundColor.hex,
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "start",
                }}
              >
                <Box
                  sx={{
                    width: ["100%", "80%", "60%"],
                    height: "60px",
                    display: "flex",
                    justifyContent: "start",
                    alignContent: "center",
                    alignItems: "center",
                    mb: [4],
                    img: {
                      width: "auto",
                      height: "100%",
                    },
                  }}
                >
                  <Image src={item.image.url} alt="" />
                </Box>
                <Box>
                  <Heading
                    variant="h1"
                    sx={{
                      color: "light",
                      fontWeight: "500",
                      letterSpacing: "-0.521739px",
                      lineHeight: "1",
                    }}
                  >
                    {item.title}
                  </Heading>
                </Box>
                <Box
                  sx={{ mb: [4], p: { color: "light", lineHeight: "20px" } }}
                >
                  <RichContentStructuredText text={item.body} />
                </Box>
                <Box>
                  <MagicLink
                    sx={{
                      display: "block",
                      border: "1px solid",
                      borderColor: "light",
                      "&:hover": {
                        backgroundColor:
                          index === 0
                            ? items[1].backgroundColor.hex
                            : items[0].backgroundColor.hex,
                      },
                    }}
                    variant="buttons.primaryTransparent"
                    item={item.link}
                  >
                    <Flex sx={{ alignItems: "center", alignContent: "center" }}>
                      <Flex
                        sx={{ alignItems: "center", alignContent: "center" }}
                        mr={[3]}
                      >
                        Prodotti {item.link.link.title}
                      </Flex>
                      <Flex
                        variant="svg.arrow.light"
                        sx={{
                          alignItems: "center",
                          alignContent: "center",
                          width: "24px",
                          height: "24px",
                        }}
                      >
                        <ArrowRight />
                      </Flex>
                    </Flex>
                  </MagicLink>
                </Box>
              </Flex>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}

export default DoubleExternalProduct
