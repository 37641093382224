import React, { useState } from "react"
import { Box, Container, Grid, Heading, Text, Flex } from "@theme-ui/components"
import { GatsbyImage } from "gatsby-plugin-image"
import FamilyThumb from "../familyThumb"
import RichContentStructuredText from "../richContentStructuredText"
import { ArrowRightCircle } from "react-feather"
import { InboundLink } from "../link"
import { getSectorPath } from "../../utils/path"
import ArrowRight from "../../assets/images/arrow-right.svg"
import { useBreakpointIndex } from "@theme-ui/match-media"
import SwiperCore, { Navigation, Mousewheel, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

const SectorsSection = ({ kicker, sectors }) => {
  const [show, setShow] = useState(0)

  const images = sectors.map(sector => sector.heroImage)
  const breakpointIndex = useBreakpointIndex()

  return (
    <Box sx={{ py: [0, 6], position: "relative" }}>
      {breakpointIndex !== 0 ? (
        <>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              height: "100%",
              width: "100%",
              backgroundColor: "primary",
              zIndex: 0,
            }}
          >
            <Grid
              columns={["0fr 1fr", 2]}
              gap={[0]}
              sx={{ height: "100%", position: "relative" }}
            >
              <Box sx={{ backgroundColor: "primary" }}></Box>
              <Box
                sx={{
                  ".gatsby-image-wrapper": {
                    width: "100%",
                    height: "100%",
                  },
                }}
              >
                <GatsbyImage image={images[show].gatsbyImageData} alt="" />
              </Box>
            </Grid>
          </Box>
          <Container sx={{ zIndex: 1, position: "relative" }}>
            <Box>
              <Text
                as="div"
                variant="sectionTitle"
                sx={{
                  div: {
                    color: "light",
                    borderColor: "light",
                  },
                }}
              >
                <Flex as="div">
                  <span>{kicker}</span>
                </Flex>
              </Text>
            </Box>
            <Grid columns={[1, 2]}>
              <Box sx={{ pt: [5] }}>
                <Box as="ul" sx={{ p: [0] }}>
                  {sectors.map((sector, index) => (
                    <Box
                      as="li"
                      sx={{
                        listStyle: "none",
                        py: [2],
                      }}
                      onClick={() => setShow(index)}
                    >
                      <Flex
                        sx={{
                          cursor: "pointer",
                          alignItems: "center",
                          justifyContent: "space-between!important",
                        }}
                      >
                        <Flex sx={{ alignItems: "center" }}>
                          <Heading
                            as="p"
                            variant="h2"
                            sx={{
                              my: [2],
                              lineHeight: "1.1",
                              fontSize: [8, 8, 8, 8],
                              fontWeight: 500,
                              color: show === index ? "light" : "lighter",
                            }}
                          >
                            {sector.shortTitle
                              ? sector.shortTitle.toLowerCase()
                              : sector.title.toLowerCase()}
                          </Heading>
                        </Flex>
                      </Flex>
                      {index === show && (
                        <Box sx={{ display: ["block", "none"], pl: [0, 10] }}>
                          <Box
                            sx={{
                              alignItems: "end",
                              pt: [2],
                              color: "light",
                              letterSpacing: "-0.369231",
                              lineHeight: "20px",
                            }}
                            dangerouslySetInnerHTML={{
                              __html: sectors[index].subtitle,
                            }}
                          />
                          <Box sx={{ display: "inline-block" }}>
                            <InboundLink
                              sx={{ display: "block" }}
                              variant="buttons.primary"
                              to={getSectorPath(
                                sectors[index],
                                sectors[index].locale
                              )}
                            >
                              <Flex
                                sx={{
                                  alignItems: "center",
                                  alignContent: "center",
                                }}
                              >
                                <Flex
                                  sx={{
                                    alignItems: "center",
                                    alignContent: "center",
                                  }}
                                  mr={[3]}
                                >
                                  Scopri di più
                                </Flex>
                                <Flex
                                  variant="svg.arrow.light"
                                  sx={{
                                    alignItems: "center",
                                    alignContent: "center",
                                    width: "24px",
                                    height: "24px",
                                  }}
                                >
                                  <ArrowRight />
                                </Flex>
                              </Flex>
                            </InboundLink>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  ))}
                </Box>
              </Box>
              <Box sx={{ pl: [0, 5, 5, 10], display: ["none", "block"] }}>
                <Box
                  sx={{
                    alignItems: "end",
                    pt: [7],
                    color: "light",
                    letterSpacing: "-0.369231",
                    lineHeight: "20px",
                  }}
                  dangerouslySetInnerHTML={{ __html: sectors[show].subtitle }}
                />
                <Box sx={{ display: "inline-block" }}>
                  <InboundLink
                    sx={{ display: "block" }}
                    variant="buttons.primary"
                    to={getSectorPath(sectors[show], sectors[show].locale)}
                  >
                    <Flex sx={{ alignItems: "center", alignContent: "center" }}>
                      <Flex
                        sx={{ alignItems: "center", alignContent: "center" }}
                        mr={[3]}
                      >
                        Scopri di più
                      </Flex>
                      <Flex
                        variant="svg.arrow.light"
                        sx={{
                          alignItems: "center",
                          alignContent: "center",
                          width: "24px",
                          height: "24px",
                        }}
                      >
                        <ArrowRight />
                      </Flex>
                    </Flex>
                  </InboundLink>
                </Box>
              </Box>
            </Grid>
          </Container>
        </>
      ) : (
        <Box
          sx={{
            px: [3],
            ".swiper-pagination-bullet ": {
              backgroundColor: "light",
            },
          }}
        >
          <Box>
            <Text
              as="div"
              variant="sectionTitle"
              sx={{
                div: {
                  color: "dark",
                  borderColor: "dark",
                  mt: 4,
                },
              }}
            >
              <Flex as="div">
                <span>{kicker}</span>
              </Flex>
            </Text>
          </Box>
          <Swiper
            spaceBetween={32}
            pagination={{ clickable: true }}
            autoHeight={false}
            breakpoints={{
              320: {
                slidesPerView: 1,
              },
            }}
          >
            {sectors.map((sector, index) => (
              <SwiperSlide key={sector.id}>
                <Box sx={{ mt: [4], backgroundColor: "primary" }}>
                  <Box
                    sx={{
                      backgroundColor: "primary",
                      ".gatsby-image-wrapper": {
                        width: "100%",
                        height: "100%",
                        minHeight: "300px",
                      },
                    }}
                  >
                    <GatsbyImage
                      image={sector.heroImage.gatsbyImageData}
                      alt=""
                    />
                  </Box>
                  <Box sx={{ p: [3] }}>
                    <Flex sx={{ alignItems: "center" }}>
                      <Heading
                        as="h4"
                        variant="h4"
                        sx={{
                          my: [2],
                          lineHeight: "1.1",
                          fontWeight: 500,
                          color: "light",
                        }}
                      >
                        {sector.title.toLowerCase()}
                      </Heading>
                    </Flex>
                    <Box
                      sx={{
                        alignItems: "end",
                        color: "light",
                        letterSpacing: "-0.369231",
                        lineHeight: "20px",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: sectors[show].subtitle,
                      }}
                    />
                  </Box>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      )}
    </Box>
  )
}

export default SectorsSection
