import React from "react"
import { Box, Container, Grid, Heading, Text, Flex } from "@theme-ui/components"
import { GatsbyImage } from "gatsby-plugin-image"
import FamilyThumb from "../familyThumb"
import RichContentStructuredText from "../richContentStructuredText"
import { MagicLink } from "../../utils/magicLink"
import { InboundLink } from "../link"
import { getSectorPath, getNewsPath } from "../../utils/path"
import ArrowRight from "../../assets/images/arrow-right.svg"
import NewsSectionArticleCard from "../newsSectionArticleCard"

const NewsSection = ({ kicker, articles, locale }) => {

  return (
    <Box>
      <Container>
        <Text as="div" variant="sectionTitle">
          <Flex as="div" sx={{ justifyContent: "space-between", alignContent:"center", alignItems:"center" }}>
            <Box>
                  News
            </Box>
            <Box >
              <Box sx={{ display: "inline-block" }}>
                <InboundLink
                  sx={{ display: "block" }}
                  variant="buttons.primaryEmpty"
                  to={getNewsPath(locale)}
                >
                  <Flex sx={{ alignItems: "center", alignContent: "center" }}>
                    <Flex
                      sx={{ alignItems: "center", alignContent: "center" }}
                      mr={[3]}
                    >
                      Leggi tutte le news
                    </Flex>
                    <Flex
                      variant="svg.arrow.red"
                      sx={{
                        alignItems: "center",
                        alignContent: "center",
                        width: "24px",
                        height: "24px",
                      }}
                    >
                      <ArrowRight />
                    </Flex>
                  </Flex>
                </InboundLink>
              </Box>
            </Box>
          </Flex>
        </Text>
        <Grid columns={[1, 2, 3]} sx={{py:7}}>
          {articles.slice(0, 3).map(article => (
            <NewsSectionArticleCard article={article} />
          ))}
        </Grid>
      </Container>
    </Box>
  )
}

export default NewsSection
