import React, { useRef, useEffect, useState } from "react"
import {
  Box,
  Flex,
  Grid,
  Heading,
  Text,
  AspectRatio,
} from "@theme-ui/components"
import { getCategoryFamilyPath } from "../utils/path"
import { GatsbyImage } from "gatsby-plugin-image"
import { i18nContext } from "../context/i18nContext"
import { Plus } from "react-feather"
import { getColor } from "@theme-ui/color"
import themeUiTheme from "../gatsby-plugin-theme-ui"
import { InboundLink } from "./link"
import ArrowRight from "../assets/images/arrow-right.svg"

const FamilyThumb = ({ family, index }) => {
  const primary = getColor(themeUiTheme, "primary")
  const dark = getColor(themeUiTheme, "dark")

  const [height, setHeight] = useState(0)
  const ref = useRef(null)

  useEffect(() => {
    if (ref.current) {
      setHeight((ref.current.clientWidth * 12) / 16)
    }
  }, [ref])

  return (
    <InboundLink
      className="family-thumb-link"
      sx={{
        color: "dark",
        position: "relative",
        display: "block",
        width: "100%",
        height: "100%",
        "> div":{
          height:"100%",
          maxHeight: "350px"
        }
      }}
      state={{ family }}
      to={getCategoryFamilyPath(family, family.locale)}
    >
      <AspectRatio ratio={1} sx={{ height:"100%"}} className="red">
        <Box
          sx={{
            overflow: "hidden",
            backgroundColor: "dark",
            width: "100%",
            height: "100%",
            position: "relative",
            ".gatsby-image-wrapper": {
              position: "absolute",
              width: ["50%"],
              height: ["50%"],
              right: [1],
              bottom: [3],
              overflow: "initial",
              img: {
                right: [2],
                left: "unset",
                width: "auto",
                height: "100%",
                objectFit: "contain!important",
              },
            },
            "&:hover .overlay": {
              width: "120px",
              height: "120px",
              transform: "scale(12,12) translateZ(0)",
            },
          }}
        >
          <Box
            className="overlay"
            sx={{
              position: "absolute",
              width: ["0px"],
              height: ["0px"],
              borderRadius: "50%",
              backgroundColor: "primary",
              right: [0],
              bottom: [0],
              zIndex: "0",
              transition: "transform 0.4s ease-out, width .6s, height .6s",
            }}
          ></Box>
          {family.thumbnailImage && (
            <GatsbyImage image={family.thumbnailImage.gatsbyImageData} alt="" />
          )}
          <Flex
            sx={{
              position: "absolute",
              top: 0,
              p: [3],
              height: "100%",
              justifyContent: "space-between",
              alignContent: "center",
              flexDirection: "column",
            }}
          >
            <Box>
              <Box sx={{ width: "100%", color: "light" }}>
                <Heading
                  as={"h2"}
                  variant={index < 2 ? "h2" : "h4"}
                  sx={{ mt: [1], mb: [3, 3, 3, 3], color: "light" }}
                >
                  {family.shortTitle ? family.shortTitle : family.title}
                </Heading>
              </Box>
              <Box
                as="p"
                sx={{ fontSize: 1, color: "light", width: "70%" }}
                dangerouslySetInnerHTML={{
                  __html: family.shortDescription,
                }}
              />
            </Box>
            <Flex sx={{ textAlign: "left", color: "light" }}>
              <Box>Vedi tutte</Box>
              <Flex
                variant="svg.arrow.light"
                sx={{
                  alignItems: "center",
                  alignContent: "center",
                  width: "24px",
                  height: "24px",
                  ml: [2, 4],
                }}
              >
                <ArrowRight />
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </AspectRatio>
    </InboundLink>
  )
}

export default FamilyThumb
